<template>
  <div class="w-full">
    <BookingSummary @change-date="handleChangeDate" />
    <BookingMembershipInfo v-if="shouldShowMembershipInfoButtons" />
    <template v-if="bookingStore.hasAppointmentDateSelected === false">
      <ClientOnly>
        <LazyBookingSlotPicker
          v-if="bookingStore.healthProfessional?.bookingID"
          class="mt-4 overflow-hidden rounded-lg border border-brick-400 pt-2"
          :service-ids="[bookingStore.service?.serviceId]"
          :location-id="bookingStore.location?.attributes.mobimedId"
          :health-professional-booking-id="bookingStore.healthProfessional.bookingID"
          @slot-selected="handleSlotSelected" />
        <template #fallback> Lade Buchungskalender…</template>
      </ClientOnly>
    </template>
    <LazyBookingForm
      v-if="bookingStore.hasAppointmentDateSelected && bookingStore.healthProfessional?.bookingID !== undefined" />
  </div>
</template>

<script setup lang="ts">
import type { Slot } from "~~/types/Slot";

const emits = defineEmits<{
  changeDate: [];
}>();

const bookingStore = useBookingStore();
const { service } = useSchedulerServices(bookingStore.service?.serviceId);
watch(service, (loadedService) => (bookingStore.schedulerService = loadedService), { immediate: true });

const shouldShowMembershipInfoButtons = computed(
  () =>
    bookingStore.hasAppointmentDateSelected &&
    bookingStore.schedulerService !== null &&
    bookingStore.healthProfessional?.bookingID !== undefined &&
    bookingStore.hasServiceBenefits === true,
);

function handleSlotSelected(slot: Slot): void {
  bookingStore.setHealthProfessionalDate(slot.date);
}

async function handleChangeDate(): Promise<void> {
  emits("changeDate");
}
</script>

<style lang="scss" scoped></style>
